
import { Options, Vue } from "vue-class-component";
import HeaderShadow from "@/components/utils/HeaderShadow.vue";
import Logo from "@/components/utils/Logo.vue";

@Options({
  components: {
    HeaderShadow,
    Logo,
  },
})
export default class NoAccess extends Vue {}
